<template>
  <worker title="在职员工信息管理" :fun-auth-other="funAuth" :jobTypeC="['见习', '试用期', '聘用期间', '借用借调']"></worker>
</template>

<script>
import Worker from './worker'

export default {
  components: { Worker },
  computed: {
    funAuth () {
      return this.$authFunsProxy
    }
  }
}
</script>
